import { DASHBOARD_ANALYTICS_REQUEST, DASHBOARD_ANALYTICS_SUCCESS, DASHBOARD_ANALYTICS_FAIL } from "./DashBoardAnalyticsConstant";


export const DashboardAnalyticsReducer = (state = { DashboardAnalyticsData: [], loading: false }, action) => {

    switch (action.type) {
        case DASHBOARD_ANALYTICS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case DASHBOARD_ANALYTICS_SUCCESS:
            return {
                ...state,
                loading: false,
                DashboardAnalyticsData: action.payload,
            }
        case DASHBOARD_ANALYTICS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state;
    }

}



