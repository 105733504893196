import {
    DESIGNATION_REQUEST, DESIGNATION_SUCCESS, DESIGNATION_FAIL
} from "../../Constant/Configuration/DesignationConstant";


export const getDesignationReducer = (state = { designation: [], loading: false }, action) => {
    switch (action.type) {
        case DESIGNATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DESIGNATION_SUCCESS:
            return {
                ...state,
                loading: false,
                designation: action.payload,
            }
        case DESIGNATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state;



    }
}