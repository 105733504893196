// reducer.js

import { BUTTON_DISABLE } from "./DisableButtonConstant"; 

const initialState = {
    disabledButtonStatus: false, // Button disabled state
};

const buttonDisableReducer = (state = initialState, action) => {
    switch (action.type) {
        case BUTTON_DISABLE:
            return {
                ...state,
                disabledButtonStatus: action.payload, // Update disabled state based on the payload
            };
        default:
            return state;
    }
};

export default buttonDisableReducer;
