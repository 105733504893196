import { CONFIGURABLE_PROFILE_REQUEST,CONFIGURABLE_PROFILE_SUCCESS, CONFIGURABLE_PROFILE_FAIL } from "./ConfigurableProfileConstant";

 
export const getAllProfileReducer = (state = { profileData: [], loading: false, }, action) => {
    switch (action.type) {
        case CONFIGURABLE_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CONFIGURABLE_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                profileData: action.payload,
            };
        case CONFIGURABLE_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state;
    }
};
