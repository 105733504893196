import { APA_GUIDE_LINE_REQUEST, APA_GUIDE_LINE_SUCCESS, APA_GUIDE_LINE_FAIL } from "../../../Constant/ApaManagement/APAGuideLine/APAGuideLineConstant";



export const getAPAGuideLineReducer = (state = { apaGuideLine: [], loading: false }, action) => {

    switch (action.type) {
        case APA_GUIDE_LINE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case APA_GUIDE_LINE_SUCCESS:
            return {
                ...state,
                loading: false,
                apaGuideLine: action.payload
            }
        case APA_GUIDE_LINE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state
    }


};
