import { SUB_MENU_TYPE_SUCCESS, SUB_MENU_TYPE_REQUEST, SUB_MENU_TYPE_FAIL } from "./SubMenuConstant";

export const getAllSubMenuReducer = (state = { subMenuData: [], loading: false, }, action) => {
  switch (action.type) {
 
    

    case SUB_MENU_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SUB_MENU_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        subMenuData: action.payload,
      };
    case SUB_MENU_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default:
      return state;
  }
};
