import {
    OFFICE_REQUEST,
    OFFICE_SUCCESS,
    OFFICE_FAIL,
} from "../../../Constant/SubordinateOffice/Office/OfficeConstant";


// export const getOfficeReducer = (state = { officeData: [] }, action) => {
//     switch (action.type) {


//         case OFFICE_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 officeData: action.payload,
//             }

//         default:
//             return state;



//     }
// }



// Initial state
const initialState = {
    officeData: [],
    loading: false,
    error: null,
};
export const getOfficeReducer = (state = initialState, action) => {
    switch (action.type) {

        case OFFICE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case OFFICE_SUCCESS:
            return {
                ...state,
                loading: false,
                officeData: action.payload,
                error: action.error, // Set the error message
            }
        case OFFICE_FAIL:
            return {
                ...state,
                loading: false,
            }

        default:
            return state;



    }
}