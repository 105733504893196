import { HIERARCHY_FAIL, HIERARCHY_REQUEST, HIERARCHY_SUCCESS } from "../../Constant/Configuration/HierarchyConstant";

export const getHierarchyReducer = (state = { hierarchy: [], loading: false }, action) => {

    switch (action.type) {
        case HIERARCHY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case HIERARCHY_SUCCESS:
            return {
                ...state,
                loading: false,
                hierarchy: action.payload
            };

        case HIERARCHY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error

            };
        default:
            return state

    }



}