
import { TOOLKIT_REQUEST, TOOLKIT_SUCCESS, TOOLKIT_FAIL } from "../../Constant/Configuration/ToolkitConstant";



export const getToolkitReducer = (state = { toolkitData: [], loading: false }, action) => {

    switch (action.type) {
        case TOOLKIT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case TOOLKIT_SUCCESS:
            return {
                ...state,
                loading: false,
                toolkitData: action.payload
            };

        case TOOLKIT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }


        default:
            return state
    }


};
