
import { SUB_SUB_MENU_TYPE_SUCCESS, SUB_SUB_MENU_TYPE_REQUEST, SUB_SUB_MENU_TYPE_FAIL } from "./SubSubMenuConstant";

export const getAllSubSubMenuReducer = (state = { subSubMenuData: [], loading: false, }, action) => {
  switch (action.type) {

    case SUB_SUB_MENU_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SUB_SUB_MENU_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        subSubMenuData: action.payload,
      };
    case SUB_SUB_MENU_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
};
