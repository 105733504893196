import { ACTIVITY_CATEGORIES_TYPE_REQUEST, ACTIVITY_CATEGORIES_TYPE_SUCCESS, ACTIVITY_CATEGORIES_TYPE_FAIL } from "../../../../Constant/ApaManagement/Categories/ActivityCategories/ActivityCategoriesConstant";



export const getActivityCategoriesReducer = (state = { activityCategories: [], loading: false }, action) => {

    switch (action.type) {

        case ACTIVITY_CATEGORIES_TYPE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ACTIVITY_CATEGORIES_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                activityCategories: action.payload
            };
        case ACTIVITY_CATEGORIES_TYPE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }


        default:
            return state
    }


};
