import { FAQ_REQUEST_SUCCESS, FAQ_REQUEST, FAQ_REQUEST_FAIL } from "../../Constant/Configuration/FAQConstan";


export const getFAQReducer = (state = { faq: [] }, action) => {

    switch (action.type) {

        case FAQ_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case FAQ_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                faq: action.payload
            };
        case FAQ_REQUEST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        default:
            return state
    }


};
