import {
    LAYER_REQUEST,
    LAYER_SUCCESS,
    LAYER_FAIL,

    CREATE_LAYER_REQUEST,
    CREATE_LAYER_SUCCESS,
    CREATE_LAYER_FAIL,

    FIND_LAYER_REQUEST,
    FIND_LAYER_SUCCESS,
    FIND_LAYER_FAIL,

    UPDATE_LAYER_REQUEST,
    UPDATE_LAYER_SUCCESS,
    UPDATE_LAYER_FAIL,

    DELETE_LAYER_REQUEST,
    DELETE_LAYER_SUCCESS,
    DELETE_LAYER_FAIL,

    CLEAR_LAYER_ERROR
} from '../../Constant/Configuration/LayerConstant';


export const getLayerReducer = (state = { layers: [],loading:false }, action) => {
    switch (action.type) {
        case LAYER_REQUEST:
        case CREATE_LAYER_REQUEST:
        case DELETE_LAYER_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
                error: null
            }

        case CREATE_LAYER_SUCCESS:
            return {
                ...state,
                loading: false,
                layers: [...state?.layers, action.payload],
                message: action.message,
                status: action.statusCode
            }
        case LAYER_SUCCESS:
            return {
                ...state,
                loading: false,
                layers: action.payload,
            }
        case DELETE_LAYER_SUCCESS:
            return {
                ...state,
                loading: false,
                layers: [
                    ...state.layers.filter((item) => item.id !== action.payload),
                ],
                message: action.message,
                status: action.status,
            };


        case CLEAR_LAYER_ERROR:
            return {
                ...state,
                loading: false,
                message: null,
                status: null,
                error: null
            }

        case LAYER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }


        default:
            return state;



    }
}




// export const findLayerReducer = (state = { layer: {} }, action) => {
//     switch (action.type) {
//         case FIND_LAYER_REQUEST:
//             return {
//                 loading: true,
//                 layer: {},
//             };
//         case FIND_LAYER_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 layer: action.payload,
//             };
//         case FIND_LAYER_FAIL:
//             return {
//                 ...state,
//                 loading: false,
//                 layer: {},
//                 error: action.payload,
//             };
//         case "EMPTY_LAYERS":
//             return {
//                 ...state,
//                 layer: {},
//             };
//         case CLEAR_LAYER_ERROR:
//             return {
//                 ...state,
//                 error: null,
//                 status: null,
//             };
//         default:
//             return state;
//     }
// };




