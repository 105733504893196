import { ROLE_TYPE_SUCCESS, ROLE_TYPE_REQUEST, ROLE_TYPE_FAIL } from "../../Constant/Configuration/RoleConstant";



export const getRoleReducer = (state = { roleData: [], loading: false, }, action) => {

    switch (action.type) {
        case ROLE_TYPE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ROLE_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                roleData: action.payload


            };
        case ROLE_TYPE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state
    }


};
