import { PUBLICATION_SUCCESS, PUBLICATION_REQUEST, PUBLICATION_FAIL } from "../../Constant/Publication/PublicationConstant";

export const getPublicationReducer = (state = { publication: [], loading: false }, action) => {
  switch (action.type) {
    case PUBLICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PUBLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        publication: action.payload,
      };
    case PUBLICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default:
      return state;
  }
};
