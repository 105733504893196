import { NOTICE_REQUEST, NOTICE_SUCCESS, NOTICE_FAIL } from "../../../Constant/Notice/Notice/NoticeConstant";

export const getNoticeAddReducer = (state = { notice: [], loading: false }, action) => {
  switch (action.type) {
    case NOTICE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case NOTICE_SUCCESS:
      return {
        ...state,
        loading: false,
        notice: action.payload,
      };
    case NOTICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default:
      return state;
  }
};
