 
import { MENU_TYPE_REQUEST, MENU_TYPE_SUCCESS, MENU_TYPE_FAIL } from "./MenuConstant";

export const getAllMenuReducer = (state = { menuData: [], loading: false, }, action) => {
  switch (action.type) {

    case MENU_TYPE_REQUEST:
      return {
        ...state,
        loading: true, 
      };


    case MENU_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        menuData: action.payload,
      };
    case MENU_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error:action.error
      }

    default:
      return state;
  }
};
