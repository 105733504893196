import { ACRONYMS_REQUEST, ACRONYMS_REQUEST_SUCCESS, ACRONYMS_REQUEST_FAIL } from "../../Constant/Configuration/AcronymsConstan";


export const getAcronymsReducer = (state = { acronyms: [], loading: false }, action) => {

    switch (action.type) {
        case ACRONYMS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ACRONYMS_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                acronyms: action.payload
            };
        case ACRONYMS_REQUEST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state
    }


};
